"use client";

import { useCallback, useEffect, useState } from "react";
import Modal from "./Modal";
import useReviewReminderModal from "@/app/hooks/useReviewReminderModal";
import useLoginModal from "@/app/hooks/useLoginModal";
import { usePathname, useRouter } from "next/navigation";
import { SimplifiedUser } from "@/app/types";
import useRegisterModal from "@/app/hooks/useRegisterModal";

const REMINDER_INTERVAL = 8 * 60 * 60 * 1000;
const OFFER_END_DATE = "February 15, 2025";

interface ReviewReminderModalProps {
  currentUser?: SimplifiedUser | null;
  hasReviews?: boolean;
}

const ReviewReminderModal: React.FC<ReviewReminderModalProps> = ({
  currentUser,
  hasReviews,
}) => {
  const [hasShownModal, setHasShownModal] = useState(false);
  const reviewReminderModal = useReviewReminderModal();
  const loginModal = useLoginModal();
  const registerModal = useRegisterModal();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const excludedPaths = ["/write-review", "/contact-us", "/about", "/register-my-center", "/claim-profile", "/faq", "/privacy-policy", "/terms-of-service", "/user-data-deletion"];

    const shouldShowModal =
      (!currentUser || !hasReviews) &&
      !excludedPaths.includes(pathname ?? "") &&
      !registerModal.isOpen &&
      !loginModal.isOpen &&
      !hasShownModal;

    if (shouldShowModal) {
      const timer = setTimeout(() => {
        reviewReminderModal.onOpen();
        setHasShownModal(true);
      }, 30000);
      return () => clearTimeout(timer);
    }

    if (currentUser?.role === "ADMIN" || currentUser?.userType === "Center") {
      return;
    }
  }, [
    currentUser, 
    hasReviews, 
    reviewReminderModal, 
    pathname, 
    registerModal.isOpen, 
    loginModal.isOpen,
    hasShownModal
  ]);
  
  const handleWriteReview = useCallback(() => {
    if (!currentUser) {
      loginModal.onOpen();
      // reviewReminderModal.onClose();
    } else {
      router.push("/write-review");
    }
    reviewReminderModal.onClose();
  }, [currentUser, loginModal, reviewReminderModal, router]);

  const handleClose = useCallback(() => {
    if (currentUser && hasReviews) {
      reviewReminderModal.onClose();
    } else if (!currentUser) {
      reviewReminderModal.onClose();
      registerModal.onOpen();
    } else if (currentUser && !hasReviews) {
      router.push("/write-review");
    }
  }, [currentUser, hasReviews, reviewReminderModal]);

  return (
    <Modal
      isOpen={reviewReminderModal.isOpen}
      onClose={undefined}
      title="🎉 Special Launch Offer!"
      body={
        <div className="space-y-4">
          <div className="text-center bg-blue-50 p-4 rounded-lg">
            <div className="text-lg font-semibold text-blue-800">
              Lifetime Pro Access
            </div>
            <div className="mt-2">
              <span className="line-through text-gray-500">€20/year</span>
              <span className="ml-2 text-2xl font-bold text-blue-600">FREE</span>
            </div>
            <div className="text-sm text-blue-600 mt-1">
              Offer ends {OFFER_END_DATE}
            </div>
          </div>

          <div className="space-y-3 text-gray-700 px-5">
            <p className="font-medium">
              🎯 Unlock all these features by simply writing <strong>at least ONE REVIEW</strong>:
            </p>
            <ul className="space-y-2 text-sm pb-6">
              <li className="flex items-center">
                <span className="text-blue-500 mr-2">✓</span>
                Access all dive pro reviews
              </li>
              <li className="flex items-center">
                <span className="text-blue-500 mr-2">✓</span>
                Connect with the diving community
              </li>
              <li className="flex items-center">
                <span className="text-blue-500 mr-2">✓</span>
                Contribute to industry transparency
              </li>
            </ul>
          </div>
        </div>
      }
      actionLabel="Get Free Lifetime Access"
      onSubmit={handleWriteReview}
    />
    // <Modal
    //   isOpen={reviewReminderModal.isOpen}
    //   // onClose={reviewReminderModal.onClose}
    //   // onClose={handleClose}
    //   onClose={undefined}
    //   title="Write a Review!"
    //   body={
    //     // <p className="text-justify">
    //     //   Your opinion matters! Help us and the community by sharing your
    //     //   review. Your feedback helps others make informed decisions.
    //     // </p>
    //     <div className="text-justify mt-1 mb-3">
    //       <p>Please <strong>register/log in and write a review</strong> to see other dive pros reviews and <strong>be a free dive pro user forever</strong>! </p>
    //       <p className="mt-3">Help us and the community by sharing your experiences. Your feedback helps others make informed decisions.</p>
    //     </div>
    //   }
    //   actionLabel="Write Review"
    //   onSubmit={handleWriteReview}
    // />
  );
};

export default ReviewReminderModal;
