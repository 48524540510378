"use client";

import Link from "next/link";

interface FooterLink {
  href: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface FooterProps {
  title: string;
  links: FooterLink[];
}

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-gray-300 sm:py-8 py-6 px-6 text-xs">
      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 sm:gap-4 gap-2 md:text-sm text-xs text-gray-600">
        <FooterSection
          title="Scuba Reputation"
          links={[
            { href: "/about", text: "About us" },
            { href: "/contact-us", text: "Contact us" },
          ]}
        />
        <FooterSection
          title="Dive Center"
          links={[
            { href: "/register-my-center", text: "Create a free account" },
            { href: "/claim-profile", text: "Claim your profile" },
          ]}
        />
        <FooterSection
          title="Dive Professional"
          links={[
            { href: "/register-dive-center", text: "Register a dive center" },
            { href: "/write-review", text: "Write a review" },
          ]}
        />
        <FooterSection
          title="General Info"
          links={[
            { href: "/faq", text: "FAQs" },
            { href: "/privacy-policy", text: "Privacy Policy" },
          ]}
        />
        <FooterSection
          title="Legal Info"
          links={[
            { href: "/terms-of-service", text: "Terms of Service" },
            { href: "/user-data-deletion", text: "User Data Deletion" },
          ]}
        />
        <FooterSection
          title="Work with us"
          links={[{ href: "/contact-us", text: "Scuba brands" }]}
        />
      </div>
      <div className="flex justify-center gap-6 sm:mt-6 mt-4">
        <a
          href="https://www.instagram.com/scubareputation/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-900"
        >
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
          </svg>
        </a>
        <a
          href="https://www.facebook.com/groups/scubainstructorsnetwork"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-600 hover:text-gray-900"
        >
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
          </svg>
        </a>
      </div>

      <div className="text-center sm:mt-8 mt-6 text-gray-500">
        © {currentYear} Scuba Reputation. All rights reserved.
      </div>
    </footer>
  );
};

const FooterSection: React.FC<FooterProps> = ({ title, links }) => (
  <div>
    <h3 className="font-semibold sm:mb-3 mb-2 text-base">{title}</h3>
    <ul>
      {links.map((link, index) => (
        <li key={index} className="mb-2">
          {link.onClick ? (
            <a
              href={link.href}
              onClick={link.onClick}
              className="text-sm hover:underline"
            >
              {link.text}
            </a>
          ) : (
            <Link href={link.href} className="text-sm hover:underline">
              {link.text}
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default Footer;